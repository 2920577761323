<template>
  <div id="ProducerShowsTemporality">
    <QsCard :qlik-ids="['jFeprY']" />
    <QsCard :qlik-ids="['yrRtre']" />

    <QsCard
      :qlik-ids="['vPrTgee', 'jMAUSPm', 'CGYFdKT']"
      title="Saisonnalité des spectacles"
      style="grid-column: 1 / span 2"
      :date="{ field: 'Jour du spectacle' }"
      tooltip="Représentation du nombre de séances en fonction de la date de représentation."
    />
  </div>
</template>

<script>
import QsCard from "@/components/Qlik/QsCard";

export default { components: { QsCard } };
</script>

<style scoped>
#ProducerShowsTemporality {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 320px);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  margin: 20px;
}
</style>
